import React, { useCallback } from "react";
import QRCode from "qrcode";
import S from "../../styled";

import { Qrcode } from "styled-icons/fa-solid";

const QRCodeButton = (props) => {
  const { name, url, width, height } = props;

  const downloadQRCodeImage = useCallback(() => {
    QRCode.toDataURL(url, { width: 256, errorCorrectionLevel: "H", margin: 1 })
      .then((url) => {
        const downloadLink = document.createElement("a");
        downloadLink.download = name || "QRCode";
        downloadLink.href = `${url}`;
        downloadLink.click();
      })
      .catch((error) => {
        console.error("Some error in downloading QR Code", error);
      });
  }, []);

  return (
    <S.Button gap={5} title="Download URL as QR Code" width={width} minWidth={width} height={height} padding={0} smallSize onClick={downloadQRCodeImage}>
      <Qrcode size={12} margin />
      QR Code
    </S.Button>
  );
};

export default QRCodeButton;

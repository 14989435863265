import React, { useRef, useState } from 'react';
import S from '../../styled';

export default function FileSelect(props) {
  const MAX_SIZE_DEFAULT = 805306368 // 768MB
  const { handleFile, accept, maxSize, uploadFromDeviceText, fileTooLargeText } = props;
  const [invalidFile, setInvalidFile] = useState(false);

  const hiddenFileInput = useRef(null);
  
  const handleClick = () => {
    hiddenFileInput.current.click();
  };  

  const handleChange = event => {
    const file = event.target.files[0];
    const maxFileSize = maxSize ? maxSize : MAX_SIZE_DEFAULT;
    if (file.size > maxFileSize) {
      setInvalidFile(true)
    } else {
      handleFile(file);
    }
  };
   
  return (
    <S.Flex column>
      <S.Button task onClick={handleClick}>{uploadFromDeviceText}</S.Button>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        accept={accept}
        style={{display: 'none'}}
      />
      {invalidFile && (
        <S.Text warning textAlignCenter marginTop={14}>
          {fileTooLargeText}
        </S.Text>
      )}
    </S.Flex>
  );
}

export const AUTHENTICATION_FACEBOOK = 'facebook';
export const AUTHENTICATION_GOOGLE = 'google';
export const AUTHENTICATION_NONE = 'none';
export const AUTHENTICATION_TIKTOK = 'tiktok';
export const AUTHENTICATION_INSTAGRAM = 'instagram';
export const LOCAL_STORAGE_AUTHENTICATION_NETWORK_TYPE = 'SIDEQIK_AUTHENTICATION_NETWORK';

export const AUTHED_STATUS_UNAUTHENTICATED = 'unauthenticated';
export const AUTHED_STATUS_UNAUTHORIZED = 'unauthorized';
export const AUTHED_STATUS_AUTHENTICATED = 'authenticated';
export const AUTHED_STATUS_INVALID = 'invalid';
export const AUTHED_STATUS_LOADING = 'loading';

export const FLASH_TYPE_SUCCESS = 'success';
export const FLASH_TYPE_ERROR = 'error';

export const CONTENT_POST_AWAITING_CONTENT_DISPLAYABLE = 'Awaiting Content';
export const CONTENT_POST_READY_TO_POST_DISPLAYABLE = 'Ready To Post';
export const CONTENT_POST_COMPLETE_DISPLAYABLE = 'Complete';
export const VIDEO_DATA_TYPE = 'video/';
export const FILE_DATA_TYPE = 'application/';

export const CONTENT_REVIEW_STATUS_APPROVED = 'approved';
export const CONTENT_REVIEW_STATUS_APPROVE_CONTENT = 'approve_content';
export const CONTENT_REVIEW_STATUS_FEEDBACK_GIVEN = 'feedback_given';
export const CONTENT_REVIEW_STATUS_AWAITING_CONTENT = 'awaiting_content';

export const POST_CONTENT_TASK = 'PostContentTask';
export const FILE = 'file';
export const CONTENT = 'content';

export const PORTAL_AFFILIATE_DISABLED = 'portal_affiliate_disabled';
export const PORTAL_PAYMENTS_DISABLED  = 'portal_payments_disabled';
export const PORTAL_CAMPAIGN_SUMMARY 	 = 'portal_campaign_summary';

export const NETWORKS = {
  blog: 'Blog',
  facebook: 'Facebook',
  google: 'Google',
  instagram: 'Instagram',
  pinterest: 'Pinterest',
  soundcloud: 'SoundCloud',
  steam: 'Steam',
  tumblr: 'Tumblr',
  twitch: 'Twitch',
  twitter: 'Twitter',
  vimeo: 'Vimeo',
  website: 'Website',
  youtube: 'YouTube',
  mixer: 'Mixer',
  snapchat: 'Snapchat',
  linkedin: 'LinkedIn',
  flickr: 'Flickr',
  patreon: 'Patreon',
  medium: 'Medium',
  spotify: 'Spotify',
  foursquare: 'Foursquare',
  tiktok: 'TikTok'
}
// eslint-disable-next-line
export const EMAIL_VALIDATION_REGEX = /(\w\.?)+@[\w\.-]+\.\w{2,}/;

export const ERROR_403 = {
  status: '403',
  error: "Unable to authentication user for portal",
  error_code: "Forbidden",
};

export const authErrors = () => {
  return {
    default: {
      name: 'default',
      message: 'We could not log you in at this time.'
    },
    PortalLockedError: {
      name: 'PortalLocked',
      message: 'Your account has been locked.'
    },
    MultipleAccountContactsError: {
      name: 'MultipleAccountContactsError',
      message: 'Unable to find unique influencer for account.'
    },
    NoAccountContactReport: {
      name: 'NoAccountContactReportError',
      message: 'We ran into an issue setting up your account. Our team has been notified and is working to resolve the issue.'
    },
    NoActiveAccountError: {
      name: 'NoActiveAccountError',
      message: 'The email you provided is not associated with this brand account.'
    },
    EmailDoesntMatchContactsError: {
      name: 'EmailDoesntMatchContactsError',
      message: 'We could not find an account with the provided email address.'
    },
    ContactBelongsToAnotherUserError: {
      name: 'ContactBelongsToAnotherUserError',
      message: 'We could not find an account with the provided email address.'
    },
    NoEmailForCreatorError: {
      name: 'NoEmailForCreatorError',
      message: 'Unable to find influencer email associated with brand account.'
    }
  }
};

export const DELEGATE_RELATIONSHIP_LOCAL_STORAGE_NAME = 'current_delegate_relationship';

import React from 'react';
import S from '../../styled';

// Icons
import {
  Mixer as MixerIcon,
} from '@styled-icons/fa-brands';
import {
  GooglePlus as GoogleIcon,
  Pinterest as PinterestIcon,
  Snapchat as SnapchatIcon,
  Tiktok as TiktokIcon,
  Youtube as YoutubeIcon,
  FlickrSquare as FlickrIcon,
  Twitter as TwitterIcon,
  Vimeo as VimeoIcon,
  Medium as MediumIcon,
  Patreon as PatreonIcon,
  Spotify as SpotifyIcon,
  LinkedinSquare as LinkedinIcon,
  FacebookCircle as FacebookIcon,
  InstagramAlt as InstagramIcon,
  Soundcloud as SoundcloudIcon,
  Steam as SteamIcon,
  Tumblr as TumblrIcon,
  Foursquare as FoursquareIcon,
} from '@styled-icons/boxicons-logos';
import {
  PlusCircle as PlusIcon,
  Link as WebsiteIcon,
  Rss as BlogIcon
} from '@styled-icons/boxicons-regular';
import { ReactComponent as TwitchIcon } from '../../assets/svg/twitch.svg';

const networkIcons = {
  blog: BlogIcon,
  facebook: FacebookIcon,
  google: GoogleIcon,
  instagram: InstagramIcon,
  pinterest: PinterestIcon,
  soundcloud: SoundcloudIcon,
  steam: SteamIcon,
  tumblr: TumblrIcon,
  twitch: TwitchIcon,
  twitter: TwitterIcon,
  vimeo: VimeoIcon,
  website: WebsiteIcon,
  youtube: YoutubeIcon,
  mixer: MixerIcon,
  snapchat: SnapchatIcon,
  linkedin: LinkedinIcon,
  flickr: FlickrIcon,
  medium: MediumIcon,
  patreon: PatreonIcon,
  spotify: SpotifyIcon,
  foursquare: FoursquareIcon,
  tiktok: TiktokIcon,
  add: PlusIcon
};

export default function NetworkIcon({
  network,
  profileUrl,
  clickable,
  grayscale,
  transparent,
}) {
  const NetworkIcon = networkIcons[network];
  const isClickable = typeof clickable === 'undefined' ? true : clickable;

  const renderIcon = () => {
    return (
      <S.SvgWrapper
        clickable={isClickable}
        circular
        height={40}
        width={40}
        network={network}
        grayscale={grayscale}
        transparent={transparent}
      >
        <NetworkIcon />
      </S.SvgWrapper>
    );
  };

  if (!NetworkIcon) return null;
  if (isClickable) {
    return (
      <S.Link target="_blank" rel="noreferrer" href={profileUrl} network>
        {renderIcon()}
      </S.Link>
    );
  } else {
    return renderIcon();
  }
}

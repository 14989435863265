import api from '../api';

export const getMagicLink = (token, email, isDelegate) =>
  api({
    method: 'post',
    url: 'authentication/send_login_email',
    data: {
      account_portal_token: token,
      email: email,
      is_delegate: isDelegate
    }
  });

export const signOut = () =>
  api({
    method: 'post',
    url: 'authentication/sign_out',
  });

export const confirmProfiles = (token) =>
  api({
    method: 'post',
    url: 'authentication/confirm_profiles',
    data: {
      account_portal_token: token
    }
  });

export const lockProfiles = (token) =>
  api({
    method: 'post',
    url: 'authentication/lock_profiles',
    data: {
      account_portal_token: token,
    },
  });

export const isAuthenticated = (token) =>
  api({
    method: 'get',
    url: `${token}/users/current`
  });

export const facebookAuthenticate = (token, facebookToken) =>
	api({
		method: 'post',
		url: 'authentication/facebook',
		data: {
			token: facebookToken,
			account_portal_token: token,
		},
	});

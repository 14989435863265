import React, { useState, useEffect } from 'react';
import S from '../../styled';

// Icons
import { Copy as CopyIcon } from '@styled-icons/fa-regular';
import { Check as CheckIcon } from '@styled-icons/fa-solid';

export default function Copyable(props) {
  const { value, name, copied, loading } = props;

  const [hasCopied, setHasCopied] = useState(false);

  const { t } = props.store;

  const copyValue = () => {
    navigator.clipboard.writeText(value);
    setHasCopied(true);
  }

  useEffect(() => {
    setHasCopied(copied);
  }, [copied])

  const resetHasCopied = () =>
    setTimeout(() => setHasCopied(false), 3000);

  useEffect(() => {
    if (hasCopied) resetHasCopied();
  }, [hasCopied]);

  const title = hasCopied ? t('components.Copyable.copySuccess') : t('components.Copyable.clickToCopy');

  return (
    <S.Flex column alignStart gap={0} width='100%' flex={1}>
      {name &&
        <S.Text lightWeight mediumSize labelCaption>
          {name}
        </S.Text>
      }
      <S.InputFake
        hoverable
        clickable
        onClick={copyValue}
        title={title}
      >
        <S.Flex
          row
          alignCenter
          justifyBetween
        >
          <S.Text labelCaption lightWeight nowrap truncate input>
            {value}
          </S.Text>
          {loading && <S.Spinner mini />}
          {!loading && (
            <S.SvgWrapper height={18} width={18} inputColor>
              {hasCopied
                ? <CheckIcon />
                : <CopyIcon />
              }
            </S.SvgWrapper>
          )}
        </S.Flex>
      </S.InputFake>
    </S.Flex>
  );
}

import React, { useState } from 'react';
import S from '../../styled';
import NetworkIcon from '../NetworkIcon';
import { Check as CheckIcon } from 'styled-icons/boxicons-regular';
import { ConnectedButton } from '../../styled/components/ConnectedButton';

export default function NetworkCard(props) {
  const {
    profile,
    connectToNetwork,
    removeNetwork,
    disconnectNetwork,
    t
  } = props;
  const { network, raw_url: rawUrl, name, username } = profile;

  const [canRemove] = useState(true);

  const onClickConnectToNetwork = (e) => {
    e.preventDefault();
    connectToNetwork(profile);
  };

  const onClickRemoveNetwork = (e) => {
    e.preventDefault();
    removeNetwork(profile);
  };

  const onClickDisconnectFromNetwork = (e) => {
    e.preventDefault();
    disconnectNetwork(profile);
  };


  return (
    <S.Flex flex={1} column alignCenter justifyCenter paddingBottom={10}>
      <S.Flex flex={1} justifyCenter>
        <S.Flex flex={0} justifyEnd>
          <NetworkIcon
            key={`${network}`}
            network={network}
            profileUrl={rawUrl}
            flex={0}
          />
        </S.Flex>
        <S.Flex
          justifyStart
          column
          alignStart
          lineHeight={1.2}
          paddingRight={10}
          flex={1}
          maxWidth
        >
          <S.Text marginLeft={10} whiteSpaceNormal overflowWrapAnywhere>
            {name || username}
          </S.Text>
          {canRemove && (
            <S.Flex
              flex={1}
              marginLeft={10}
              justifyStart
              alignStart
              alignBaseline
              flexWrap
              overflow="hidden"
            >
              <S.Link marginLeft={0} marginTop={3} onClick={onClickRemoveNetwork}>
                <S.Text as="span" mediumSize lightWeight>
                  {t('components.NetworkCard.notYou')} <S.Text as="span" mediumSize lightWeight underline>{t('components.NetworkCard.remove')}</S.Text>
                </S.Text>
              </S.Link>
            </S.Flex>
          )}
        </S.Flex>
        <S.Flex flex={0}>
          {
            profile.connected && (
              <ConnectedButton
                height={12}
                width={100}
                smallSize
                onClick={onClickDisconnectFromNetwork}>
                <span><CheckIcon width={18}/> {t('components.NetworkCard.connected')}</span>
              </ConnectedButton>
            )
          }
          {
            !profile.connected && (
              <S.Button
                height={12}
                width={100}
                network={profile.network}
                smallSize
                onClick={onClickConnectToNetwork}>
                {t('components.NetworkCard.connect')}
              </S.Button>
            )
          }
          <S.Visibility tablet width={10} />
        </S.Flex>
      </S.Flex>
    </S.Flex>
  );
}

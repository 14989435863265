import styled, { css } from 'styled-components';
import {
  height,
  position,
  padding,
  margin,
  overflow,
  flex,
  rowGap,
  width,
  clickable,
  borderRadius,
  whiteSpace,
  fontSize,
  lineHeight,
  breakpointNames,
} from '../Mixins';
import { responsiveBackgroundColor, responsiveWidth } from '../ResponsiveMixins';
import { breakpoint } from 'styled-components-breakpoint';

export const base = css`
  width: 100%;
  align-items: center;
  justify-content: center;
  flex: ${({ flex }) => (flex !== undefined ? flex : 1)};
  color: ${({ color }) => color || 'unset'};
  transition: ${({ hoverable }) => (hoverable ? 'all 0.3s ease-in-out' : null)};
  background-color: ${({ theme, highlight }) => {
    if (highlight) return theme.text.backgroundColor.highlight;
    return null;
  }};
  ::-webkit-scrollbar {
    background: ${({ theme }) => theme.module.color.background};
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.body.color.shading};
    -webkit-border-radius: 1ex;
    -webkit-box-shadow: ${({ theme }) =>
      `0px 1px 1px ${theme.body.color.shading}`};
  }

  ::-webkit-scrollbar-corner {
    background: ${({ theme }) => theme.module.color.background};
  }

  flex-direction: ${({ flexDirection, flex }) =>
    flexDirection ? flexDirection : flex
  };

  color: ${({ color, muted, theme }) => {
    if (color) return color;
    if (muted) return theme.text.color.muted;
    return 'unset';
  }};

  ${(border) => (border ? border : null)}

  ${clickable}
  ${position}
  ${height}
  ${padding}
  ${margin}
  ${flex}
  ${overflow}
  ${whiteSpace}
  ${fontSize}
  ${lineHeight}
  ${width}
  ${rowGap}
  ${borderRadius}
  ${responsiveWidth}
  ${responsiveBackgroundColor}

  &:hover {
    opacity: ${({ hoverable }) => (hoverable ? '0.5' : null)};
  }
`;

const Flex = styled.div`
  ${base}
`;

export default Flex;

import { css } from 'styled-components';
import styledBreakpoint from 'styled-components-breakpoint';
import { darken } from 'polished';

// styled-components-breakpoint: https://www.npmjs.com/package/styled-components-breakpoint#default-breakpoints
export const breakpointNames = {
  mobile: 'mobile',
  tablet: 'tablet',
  desktop: 'desktop'
};
export const breakpoint = (breakpointName, style) => {
  const breakpointNamesArr = Object.keys(breakpointNames);
  if (!breakpointNamesArr.includes(breakpointName)) {
    console.error(`Breakpoint name must be ${breakpointNamesArr.join(', ')}`);
    return;
  }
  return styledBreakpoint(breakpointName)`${style}`;
};

const attrAsPixels = (attr) => (attr || attr === 0 ? attr + 'px' : null);

export const clickable = css`
  cursor: ${({ clickable }) => clickable ? 'pointer' : null};
`;

export const width = css`
  width: ${({ width, maxWidth }) => {
    const widthType = typeof width;

    if (width && widthType === 'string' && width.match(/\d%/)) {
      return width;
    } else if (width && widthType === 'number') {
      return attrAsPixels(width);
    } else if (!width && maxWidth) {
      return '100%';
    }
  }};
  max-width: ${({ maxWidth }) => {
    const maxWidthType = typeof maxWidth;
    if (maxWidthType === 'boolean') return '100%';
    else if (maxWidthType === 'string') return maxWidth;
    else if (maxWidthType === 'number') return attrAsPixels(maxWidth);
  }};
  min-width: ${({ minWidth }) => {
    const minWidthType = typeof minWidth;
    if (minWidthType === 'boolean') return '100%';
    else if (minWidthType === 'string') return minWidth;
    else if (minWidthType === 'number') return attrAsPixels(minWidth);
  }};
`;

export const border = css`
  border: ${({
    theme,
    borderHeight = '1px',
    borderStyle = 'solid',
    borderColor = theme.border.color.divider
  }) => `${borderHeight} ${borderStyle} ${borderColor}`
  };
`;

export const height = css`
  height: ${({ height }) => {
    const heightType = typeof height;
    if (height && heightType === 'string' && height.match(/\d%/)) {
      return height;
    } else if (height && heightType === 'number') {
      return attrAsPixels(height);
    }
  }};
  min-height: ${({ minHeight }) => {
    const minHeightType = typeof minHeight;
    if (minHeightType === 'boolean') return '100%';
    else if (minHeightType === 'string') return minHeight;
    else if (minHeightType === 'number') return attrAsPixels(minHeight);
  }};
`;

export const padding = css`
  padding: ${({ padding }) => attrAsPixels(padding)};
  padding-bottom: ${({ paddingBottom }) => attrAsPixels(paddingBottom)};
  padding-top: ${({ paddingTop }) => attrAsPixels(paddingTop)};
  padding-left: ${({ paddingLeft }) => attrAsPixels(paddingLeft)};
  padding-right: ${({ paddingRight }) => attrAsPixels(paddingRight)};
`;

export const margin = css`
  margin: ${({ margin }) => attrAsPixels(margin)};
  margin-bottom: ${({ marginBottom }) => attrAsPixels(marginBottom)};
  margin-top: ${({ marginTop }) => attrAsPixels(marginTop)};
  margin-left: ${({ marginLeft }) => attrAsPixels(marginLeft)};
  margin-right: ${({ marginRight }) => attrAsPixels(marginRight)};
`;

export const position = css`
  position: ${({ position, positionRelative, positionAbsolute, positionFixed }) => {
    if (position) return position;
    if (positionRelative) return 'relative';
    if (positionAbsolute) return 'absolute';
    if (positionFixed) return 'fixed';
  }};
  top: ${({ top }) => attrAsPixels(top)};
  bottom: ${({ bottom }) => attrAsPixels(bottom)};
  right: ${({ right }) => attrAsPixels(right)};
  left: ${({ left }) => attrAsPixels(left)};
`;

export const gap = css`
  gap: ${({ gap }) => attrAsPixels(gap)};
`;

export const rowGap = css`
  row-gap: ${({ rowGap }) => attrAsPixels(rowGap)};
`;

export const displayInline = css`
  display: inline;
`;

export const color = css`
  color: ${({ color }) => {
    if (color) return color;
    return 'inherit';
  }};
`;

export const lineHeight = css`
  line-height: ${({ lineHeight }) => {
    if (lineHeight !== undefined) return lineHeight;
    return 'inherit';
  }};
`;

export const textUnderline = css`
  text-underline-offset: ${({ textUnderlineOffset }) => {
    if (textUnderlineOffset) return attrAsPixels(textUnderlineOffset);
  }};
  text-decoration-thickness: ${({ textDecorationThickness }) => {
    if (textDecorationThickness) return attrAsPixels(textDecorationThickness);
  }};
`;

export const backgroundColor = css`
  background-color: ${({ theme, outline, secondary, backgroundColor, network, error, disabled, task }) => {
    if (outline) return theme.button.color.outlineBackground;
    if (secondary || disabled) return theme.button.color.secondary;
    if (error) return theme.button.color.error;
    if (backgroundColor) return backgroundColor;
    if (network) return networkColor(network);
    if (task) return theme.button.color.outline;
    return theme.button.color.default;
  }};
  &:hover {
    background-color: ${({ theme, outline, secondary, backgroundColor, network, task }) => {
      let color = theme.button.color.default;
      if (outline) {color = theme.button.color.outlineBackground}
      if (secondary) { color = theme.button.color.secondary}
      if (backgroundColor) { color = backgroundColor}
      if (network) color = networkColor(network);
      if (task) {color = theme.button.color.outline}
      return darken(0.1, color);
    }};
  }
`;

export const truncate = ({ truncate, whiteSpaceInitial }) => {
  const whiteSpace = whiteSpaceInitial ? 'initial' : 'nowrap';
  if (truncate) {
    return css`
      width: 100%;
      display: block;
      overflow-x: hidden;
      overflow-y: hidden;
      text-overflow: ellipsis;
      white-space: ${whiteSpace};
    `;
  }
  return null;
}

export const flex = css`
  display: flex;
  text-align: ${({ textAlignCenter, textAlignLeft, textAlignRight, textAlign }) => {
    if (textAlignCenter) return 'center';
    if (textAlignLeft) return 'left';
    if (textAlignRight) return 'right';
    if (textAlign) return textAlign;
  }};
  align-self: ${({ alignSelfStart, alignSelfCenter, alignSelfEnd }) => {
    if (alignSelfStart) return 'flex-start';
    if (alignSelfCenter) return 'center';
    if (alignSelfEnd) return 'flex-end';
  }};
  align-items: ${({ alignItems, alignStart, alignCenter, alignEnd, alignBaseline }) => {
    if (alignBaseline) return 'baseline';
    if (alignItems) return alignItems;
    if (alignStart) return 'flex-start';
    if (alignCenter) return 'center';
    if (alignEnd) return 'flex-end';
  }};
  justify-content: ${({ justifyContent, justifyStart, justifyCenter, justifyEnd, justifyBetween, justifyEvenly, justifyAround }) => {
    if (justifyContent) return justifyContent;
    if (justifyStart) return 'flex-start';
    if (justifyCenter) return 'center';
    if (justifyEnd) return 'flex-end';
    if (justifyBetween) return 'space-between';
    if (justifyEvenly) return 'space-evenly';
    if (justifyAround) return 'space-around';
  }};
  flex-direction: ${({ direction, row, column }) => {
    if (direction) return direction;
    if (row) return 'row';
    if (column) return 'column';
  }};
  flex: ${({ flex }) => flex};
  flex-wrap: ${({ flexWrap, flexNoWrap, flexWrapReverse }) => {
    if (flexWrap) return 'wrap';
    if (flexNoWrap) return 'nowrap';
    if (flexWrapReverse) return 'wrap-reverse';
  }};
  flex-basis: ${({ flexBasis }) => flexBasis};
  ${gap}
`;

export const grid = css`
  display: grid;
  column-gap: ${({ columnGap }) => columnGap};
  row-gap: ${({ rowGap }) => rowGap};
  gap: ${({ gap }) => gap};
  grid-column-start: ${({ gridColumnStart }) => gridColumnStart};
  grid-column-end: ${({ gridColumnEnd }) => gridColumnEnd};
  grid-auto-rows: ${({ gridAutoRows }) => gridAutoRows};
  grid-auto-columns: ${({ gridAutoColumns }) => gridAutoColumns};
  grid-auto-flow: ${({ row, column }) => {
    if (row) return 'row';
    if (column) return 'column';
  }};
`;

export const overflow = css`
  overflow-y: ${({ overflow, overflowAuto, overflowHidden }) => {
    if (overflow) return overflow;
    if (overflowAuto) return 'auto';
    if (overflowHidden) return 'hidden';
  }};
`;

export const whiteSpace = css`
  white-space: ${({ whiteSpace, whiteSpaceNoWrap, whiteSpacePre, whiteSpacePreWrap, whiteSpacePreLine, whiteSpaceBreakSpaces, whiteSpaceNormal }) => {
    if (whiteSpace) return whiteSpace;
    if (whiteSpaceNoWrap) return 'nowrap';
    if (whiteSpacePre) return 'pre';
    if (whiteSpacePreWrap) return 'pre-wrap';
    if (whiteSpacePreLine) return 'pre-line';
    if (whiteSpaceBreakSpaces) return 'break-space';
    if (whiteSpaceNormal) return 'normal';
  }};
`;

export const wordBreak = css`
  word-break: ${({
    wordBreak,
    wordBreakAll,
    wordBreakWord,
    wordBreakInherit,
    wordBreakInitial,
    wordBreakKeepAll,
    wordBreakNormal,
    wordBreakRevert,
    wordBreakRevertLayer,
    wordBreakUnset,
  }) => {
    if (wordBreak) return wordBreak;
    if (wordBreakAll) return 'break-all';
    if (wordBreakWord) return 'break-word';
    if (wordBreakInherit) return 'inherit';
    if (wordBreakInitial) return 'initial';
    if (wordBreakKeepAll) return 'keep-all';
    if (wordBreakNormal) return 'normal';
    if (wordBreakRevert) return 'revert';
    if (wordBreakRevertLayer) return 'revert-layer';
    if (wordBreakUnset) return 'unset';
  }};
`;

export const textTransform = css`
  text-transform: ${({ uppercase, capitalize }) => {
    if (uppercase) return 'uppercase';
    if (capitalize) return 'capitalize';
    return 'unset';
  }};
`;

export const transform = css`
  transform: ${({ scale }) => {
    if (scale) return `scale(${scale})`;
  }};
`;

export const fontSize = css`
  font-size: ${({ theme, fontSize, headerSize, largeSize, mediumSize, smallSize }) => {
    if (fontSize) return attrAsPixels(fontSize);
    if (headerSize) return theme.text.fontSize.large;
    if (largeSize) return theme.text.fontSize.large;
    if (mediumSize) return theme.text.fontSize.medium;
    if (smallSize) return theme.text.fontSize.small;
    return theme.fontSize;
  }};
  ${styledBreakpoint('tablet')`
    font-size: ${({ theme, fontSize, headerSize, largeSize, mediumSize, smallSize }) => {
      if (fontSize) return attrAsPixels(fontSize);
      if (headerSize) return theme.text.fontSize.header;
      if (largeSize) return theme.text.fontSize.large;
      if (mediumSize) return theme.text.fontSize.medium;
      if (smallSize) return theme.text.fontSize.small;
      return theme.fontSize;
    }};
  `}
`;

export const textDecoration = css`
  text-decoration: ${({
    underline,
    strikethrough,
    textDecorationNone,
    navigation,
    textDecoration
  }) => {
    if (underline) return 'underline';
    if (strikethrough) return 'line-through';
    if (textDecorationNone || navigation) return 'none';
    if (textDecoration) return textDecoration;
  }};
`;

export const borderRadius = css`
  border-radius: ${({ borderRadius }) => {
    if (borderRadius) return attrAsPixels(borderRadius);
  }};
`;

const networkColors = {
  blog: '#bf9d4e',
  facebook: '#435d96',
  google: '#d14836',
  instagram: '#f68200',
  pinterest: '#c8232a',
  soundcloud: '#ff8800',
  steam: '#111',
  tumblr: '#34526f',
  twitch: '#6441a5',
  twitter: '#55acee',
  vimeo: '#4ebbff',
  website: '#878787',
  youtube: '#ff0000',
  mixer: '#35465c',
  snapchat: '#faed00',
  linkedin: '#0e76a8',
  flickr: '#0063dc',
  medium: '#35465c',
  patreon: '#052d49',
  spotify: '#1db954',
  foursquare: '#0d389e',
  tiktok: '#010101',
  add: '#000000',
};

export const networkColor = (network) => {
  if (!network || !networkColors[network]) return '#111';
  return `${networkColors[network]}`;
};

import React from 'react'
import CampaignProgramsModule from './';

const AvailableCampaignProgramsModule = function () {
  return (props) => {
    const { store, available, limit, dashboardView, title } = props;

    if (dashboardView) {
      return <CampaignProgramsModule 
               store={store}
               dashboardView={dashboardView}
               title={title}
               available={true}
               limit={3} />
    } else {
      return <CampaignProgramsModule 
               store={store}
               available={available}
               title={title}
               limit={limit} 
               clear />
    }
  };
};

export default AvailableCampaignProgramsModule(CampaignProgramsModule);

import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import numeral from 'numeral';
import S from '../../styled';
import getSymbolFromCurrency from 'currency-symbol-map';

// Routes
import { routeNames } from '../../routing/routes';

// Feature Flag
import { useFeatureFlag } from '../../helpers/useFeatureFlag';

// Model Requests
import { getCurrentUserProfileByToken } from '../../data/requests/profile';
import { getPerformance } from '../../data/requests/performance';

// Components
import NetworkIcon from '../NetworkIcon';
import CircleIcon from '../CircleIcon';
import Metric from '../Metric';

// Icons
import {
  User as UserIcon
} from '@styled-icons/fa-solid';

// Helpers
import navigateTo from '../../helpers/navigateTo';
import NavigationContent from '../NavigationContent';

const ProfileModule = (props) => {
  const { store, setStore, socialProfiles, routeSettings } = props;
  const { token, currentUser, currentProfile, currentPerformance, account, t } = store;
  const { setCurrentProfile, setCurrentPerformance } = setStore;

  const history = useHistory();

  const [profileImageError, setProfileImageError] = useState(false);
  const connectFeatureFlag = useFeatureFlag(store, 'portal_connect');
  const isPortalAffiliateDisabled = useFeatureFlag(store, 'portal_affiliate_disabled');

  const hideProfile = (routeSettings?.hideProfile && typeof routeSettings.hideProfile === 'boolean') ? routeSettings.hideProfile : false;

  const navigateToNetworks = (e) => {
    e.preventDefault();
    if (connectFeatureFlag) navigateTo(history, routeNames.networkConnect, token);
  };

  const navigateToPerformance = (e) => {
    e.preventDefault();
    navigateTo(history, routeNames.performance, token);
  };

  const navigateToSettings = (e) => {
    e.preventDefault();
    if (!account.settings_enabled) {
      navigateToNetworks(e);
    }
    navigateTo(history, routeNames.settings, token);
  };

  const getCurrentUserProfile = useCallback(async () => {
    if (!token || hideProfile) {
      setCurrentProfile(null);
      return;
    }

    try {
      if (!currentProfile) {
        const profile = await getCurrentUserProfileByToken(token);
        setCurrentProfile(profile);
        const performance = await getPerformance(token);
        setCurrentPerformance(performance);
      }
    } catch (error) {
      console.error('getCurrentUserProfileByToken: ', error);
    }
  }, [token, currentUser, currentProfile, hideProfile]);

  useEffect(() => {
    getCurrentUserProfile();
  }, [getCurrentUserProfile]);

  if (!currentProfile && !hideProfile) {
    return (
      <S.ProfileModule>
        <S.Flex column>
          <S.Spinner />
        </S.Flex>
      </S.ProfileModule>
    );
  } else if (!currentProfile?.id && !hideProfile) {
    return (
      <S.ProfileModule>
        <S.Flex row>
          <S.SvgWrapper height={100} width={100} marginLeft={4}>
            <UserIcon />
          </S.SvgWrapper>
          <S.Text medium light marginLeft={20}>
            {t('components.ProfileModule.errorLoadingProfileData')}
          </S.Text>
        </S.Flex>
      </S.ProfileModule>
    );
  } else {
    const name = currentProfile?.name;
    const imageUrl = currentProfile?.profile_image_url;
    const revenueCurrencySymbol = getSymbolFromCurrency(
      currentPerformance?.revenue_currency || 'USD'
    );
    const reach = numeral(
      currentProfile?.reach || 0
    ).format('0a');
    const revenueAmount = currentPerformance?.revenue || 0;
    const currencyFormat =
      revenueAmount < 1000
        ? revenueCurrencySymbol + '0,0'
        : revenueCurrencySymbol + '0,0.0a';
    const revenue = numeral(revenueAmount).format(currencyFormat);

    return (
      <S.ProfileModule>
        {!hideProfile && (
          <>
            <S.Flex row justifyBetween>
              <S.Flex flex={1}>
                {!isPortalAffiliateDisabled && (
                  <Metric
                    name={t('components.ProfileModule.followers')}
                    clickable={connectFeatureFlag}
                    value={reach}
                    onClick={navigateToNetworks}
                  />
                )}
              </S.Flex>
              <S.Flex column flex={1} clickable onClick={navigateToSettings}>
                {profileImageError ? (
                  <S.SvgWrapper height={100} width={100}>
                    <UserIcon />
                  </S.SvgWrapper>
                ) : (
                  <S.Image
                    flex={1}
                    src={imageUrl}
                    alt={`${name} profile image`}
                    width={120}
                    height={120}
                    onError={() => setProfileImageError(true)}
                  />
                )}
              </S.Flex>
              <S.Flex flex={1}>
                {!isPortalAffiliateDisabled && (
                  <Metric
                    name={t('components.ProfileModule.revenue')}
                    clickable
                    value={revenue}
                    onClick={navigateToPerformance}
                  />
                )}
              </S.Flex>
            </S.Flex>
            <S.Flex
              row
              textAlignCenter
              alignCenter
              justifyCenter
              flex={1}
              marginTop={14}
              clickable
              onClick={navigateToSettings}
            >
              <S.Text flex={1} maxWidth={150}>
                {name}
              </S.Text>
            </S.Flex>
            <S.Flex
              row
              alignCenter
              justifyCenter
              flexWrap
              gap={8}
              marginTop={8}
            >
              {socialProfiles.map(({ id, network, raw_url }) => (
                <NetworkIcon
                  transparent
                  key={`${id}-${network}`}
                  network={network}
                  profileUrl={raw_url}
                  grayscale
                />
              ))}

              {connectFeatureFlag && (
                <CircleIcon onClick={navigateToNetworks} />
              )}
            </S.Flex>
          </>
        )}
        <S.Visibility tablet desktop alignStart>
          <S.Flex
            flex={2}
            name="flex"
            paddingTop={hideProfile ? 0 : 70}
            alignStart
          >
            <NavigationContent {...props} desktopMode />
          </S.Flex>
        </S.Visibility>
      </S.ProfileModule>
    );
  }
};

export default ProfileModule;

import { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// Routes
import { paths } from '../../routing/routes';

// Styles
import S from '../../styled';

// Model Requests
import { getContentReviews, getPostContentTask } from '../../data/requests/task';
import { getUploadsByParentTaskId } from '../../data/requests/uploads';

// Components
import Page from '../../components/Page';
import Module from '../../components/Module';
import TaskCard from '../../components/TaskCard';
import DownloadFileLinks from '../../components/DownloadFileLinks';
import ModuleHeader from '../../components/ModuleHeader';
import TaskInstructions from '../../components/TaskInstructions';

// Helpers
import navigateTo from '../../helpers/navigateTo';
import { taskNameWithDueDateObject } from '../../helpers/taskNameDueDate';

export default function PostContentTask(props) {
  const history = useHistory();

  const [postContentTask, setPostContentTask] = useState({});
  const [contentReviews, setContentReviews] = useState([]);
  const [uploads, setUploads] = useState([]);
  const [isLoadingPostContent, setIsLoadingPostContent] = useState(true);
  const [isLoadingUploads, setIsLoadingUploads] = useState(true);

  const { store } = props;
  const { taskId: postContentTaskIdQueryParam } = props.match.params;

  const { token } = store;

  const sortContentReviews = (contentReviewsToBeSorted) => {
    if (!contentReviewsToBeSorted) return null;
    return contentReviewsToBeSorted.sort((a, b) => (a.id <= b.id) ? -1 : 1);
  }

  const getContentReviewsData = useCallback(async () => {
    if (!token) return;

    setIsLoadingPostContent(true);
    try {
      const contentReviewsToBeSorted = await getContentReviews(token, postContentTaskIdQueryParam);
      const sortedContentReviews = sortContentReviews(contentReviewsToBeSorted);
      setContentReviews(sortedContentReviews);

      const task = await getPostContentTask(token, postContentTaskIdQueryParam);
      setPostContentTask(task);
    } catch (error) {
      console.error('getContentReviews: ', error);
    } finally {
      setIsLoadingPostContent(false);
    }
  }, [token, postContentTaskIdQueryParam]);

  const getUploads = useCallback(async () => {
    const parentTaskId = postContentTask.parent_task_id;
    if (!token || !parentTaskId) return;

    setIsLoadingUploads(true);
    try {
      const lookupUploads = await getUploadsByParentTaskId(token, parentTaskId);
      setUploads(lookupUploads);
    } catch (error) {
      console.error('getUploadsByTaskId', error);
    } finally {
      setIsLoadingUploads(false);
    }
  }, [token, postContentTask]);

  useEffect(() => {
    getContentReviewsData();
  }, [getContentReviewsData]);

  useEffect(() => {
    getUploads();
  }, [getUploads]);

  const processTaskClick = (review) => {
    navigateTo(
      history,
      paths.contentStatus,
      token,
      { 
        queryParams: { 
          taskId: postContentTaskIdQueryParam,
          reviewId: review.id
        } ,
        pathKeyisFullPath: true
      }
    );
  }

  const moduleTitle = (
    <ModuleHeader moduleName={taskNameWithDueDateObject(postContentTask)} history={history} />
  );

  const getReview = (index) => {
    if (!contentReviews) return;
    if (contentReviews.length > index) return contentReviews[index];
  }

  const taskCards = (
    // NOTE: Some require review and others don't so we need the quantity. Otherwise
    // we risk rendering nothing in the case of no ContentReviews
    postContentTask?.quantity && Array.apply(
      null,
      { length: postContentTask.quantity }
    ).map((_, index) => {
      const review = getReview(index);

      return (
        <TaskCard
          key={`Task-${index}`}
          index={index}
          task={postContentTask}
          review={review}
          processTaskClick={processTaskClick}
        />
      )
    })
  );

  const contentReviewCards = (
    <S.Flex column alignCenter flex={1}>
      <S.Flex
        column
        tabletStyles={{ width: '80%' }}
        desktopStyles={{ width: '80%' }}
        paddingTop={40}
      >
        {taskCards}
      </S.Flex>
    </S.Flex>
  );

  const isLoading = isLoadingPostContent || isLoadingUploads

  return (
    <Page>
      <Module
        flex={1}
        titleBlock={moduleTitle}
        isLoading={isLoading}
      >
        <TaskInstructions instructionsHtml={postContentTask?.instructions_html} />
        <DownloadFileLinks uploads={uploads} />
        {contentReviewCards}
      </Module>
    </Page>
  );
}

import api from '../api';

const baseUrl = (token) => `profiles/${token}`;

export const getCurrentUserProfileByToken = async (token) =>
  api({
    method: 'get',
    url: `${baseUrl(token)}`,
  });

export const addTikTokAccount = (token, profile) =>
  api({
    method: 'post',
    url: `${baseUrl(token)}`,
    data: {
      type: 'tiktok',
      username: profile
    }
  });

export const connectTikTokAccount = (token, profile) =>
  api({
    method: 'patch',
    url: `${baseUrl(token)}/${profile.id}`,
    data: {
      type: 'tiktok',
      username: profile.username
    }
  });

export const removeNetwork = async (token, profile) =>
  api({
    method: 'delete',
    url: `${baseUrl(token)}/${profile.id}`
  });

export const disconnectNetwork = async (token, profile) =>
  api({
    method: 'patch',
    url: `${baseUrl(token)}/${profile.id}/disconnect`
  });

import React from 'react';
import DOMPurify from 'dompurify';

// Styles
import S from '../../styled';

export default function HtmlContent(props) {
  const { content, textAlign } = props;

  const iFrameRegex = new RegExp('<iframe(.+)</iframe>');
  const includesIframe = iFrameRegex.test(content)
 
  const purifyHTML = (dirtyHTML, includesIframe) => {
    return includesIframe ? DOMPurify.sanitize(dirtyHTML, { ALLOWED_TAGS: ["iframe"], ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'] }) : DOMPurify.sanitize(dirtyHTML);
  };

  return (
    <S.HtmlWrapper
      textAlign={textAlign}
      mediumWeight
      dangerouslySetInnerHTML={{ __html: purifyHTML(content, includesIframe) }}
    />
  );
}

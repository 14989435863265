import styled from 'styled-components';
import { margin, padding } from '../Mixins';

const HtmlWrapper = styled.div`
  width: 100%;
  text-align: ${({ textAlign }) => textAlign || null};
  font-size: 14px;
  font-weight: 400;

  p {
    line-height: 22.4px;
    margin-bottom: 15px;
  }

  font-family: Arial, Helvetica, Verdana, Tahoma;
  ${margin}
  ${padding}

  font-weight: ${({ theme, lightWeight, mediumWeight, boldWeight }) => {
    if (lightWeight) return theme.text.fontWeight.light;
    if (mediumWeight) return theme.text.fontWeight.medium;
    if (boldWeight) return theme.text.fontWeight.bold;
    return theme.fontWeight
  }};

  img {
    max-width: 100% !important;
  }

  ol, ul {
    all: revert;
  }

  a {
    color: ${({ theme }) => theme.link.color.default};
    :visited {
      color: ${({ theme }) => theme.link.color.visited};
    }
  }
`;

export default HtmlWrapper;

import api from '../api';

const baseUrl = (token) => `program_applications/${token}`;

export const signUpForCampaignProgram = (token, programId) =>
  api({
    method: 'post',
    url: `${baseUrl(token)}/${programId}`
  });

export const checkForApplication = (token, programId) =>
  api({
    method: 'get',
    url:`${baseUrl(token)}/${programId}/application`
  });

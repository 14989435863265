import styled from 'styled-components';
import { padding, margin, breakpointNames, breakpoint } from '../Mixins';

const DashboardLabel = styled.label`
  color: ${({ theme }) => theme.label.color.heading};
  font-weight: ${({ theme }) => theme.label.fontWeight};
  font-family: ${({ theme }) => {
    if (theme.dashboard) return `${theme.dashboard.fontFamily}, 'Poppins', sans-serif !important`
  }};
  font-size: ${({ theme }) => {
    if (theme.dashboard) return theme.dashboard.fontSize.larger;
    return '48px';
  }};
  display: none;
  ${padding}
  ${margin}
  width: 100%;

  ${breakpoint(breakpointNames.tablet, `
    font-family: ${({ theme }) => {
      if (theme.dashboard) return `${theme.dashboard.fontFamily}, 'Poppins', sans-serif !important`
    }};
    font-size: ${({ theme }) => {
      if (theme.dashboard) return theme.dashboard.fontSize.larger;
      return '48px';
    }};
    display: flex;
    font-weight: 600;
    letter-spacing: -1px;
    padding-left: 10px;
    align-self: flex-start;
  `)}
`;

export default DashboardLabel;

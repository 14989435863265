import React from 'react';
import S from '../../styled';

export default function Module(props) {
  const {
    title,
    subtitle,
    flex,
    width,
    height,
    minHeight,
    paddingBottom,
    paddingTop,
    paddingRight,
    paddingLeft,
    marginBottom,
    marginTop,
    marginRight,
    marginLeft,
    borderColor,
    description,
    hideDescription,
    topRightContent,
    isLoading,
    isEmpty,
    isRenderable = true,
    emptyContent,
    hasError,
    errorContent,
    outlined,
    clear,
    titleBlock,
    children,
  } = props;

  const shouldRenderDescription = () => description && !hideDescription;

  if (isLoading) {
    return (
      <S.Module width={width} flex={flex}>
        <S.Flex alignCenter justifyCenter flex={1}>
          <S.Spinner />
        </S.Flex>
      </S.Module>
    );
  }

  const renderEmptyContent = () => emptyContent || <S.Text muted>No records found.</S.Text>;

  const renderErrorContent = () => errorContent || <S.Text muted>Oops! There was a problem.</S.Text>;

  const renderMainContent = () => {
    if (hasError) return renderErrorContent();
    if (isEmpty) return renderEmptyContent();
    else return children;
  }

  const defaultTitleBlock = (
    <S.Flex flex={1} whiteSpaceNoWrap justifyStart>
      <S.Text headerSize as="h2" flex={1}>
        {title}
      </S.Text>
    </S.Flex>
  );

  if (!isRenderable) return null;
  // TODO: The loading should be more animated and fade in and out
  return (
    <S.Module
      width={width}
      height={height}
      minHeight={minHeight}
      paddingLeft={paddingLeft}
      paddingRight={paddingRight}
      paddingBottom={paddingBottom}
      paddingTop={paddingTop}
      marginLeft={marginLeft}
      marginRight={marginRight}
      marginBottom={marginBottom}
      marginTop={marginTop}
      borderColor={borderColor}
      outlined={outlined}
      clear={clear}
      maxWidth
      flex={flex}
    >
      <S.Flex
        row
        alignCenter
        justifyStart
        paddingBottom={14}
        flex={0}
      >
        <S.Flex column alignCenter justifyStart>
          <S.Flex row alignCenter justifyStart>
            {titleBlock ? titleBlock : defaultTitleBlock}
            <S.Flex whiteSpaceNoWrap alignCenter>
              <S.Text muted mediumSize boldWeight justifyEnd>
                {subtitle}
              </S.Text>
            </S.Flex>
          </S.Flex>
          {shouldRenderDescription() && (
            <S.Flex paddingTop={14} paddingBottom={14}>
              <S.Text muted smallSize lightWeight>
                {description}
              </S.Text>
            </S.Flex>
          )}
        </S.Flex>
        {topRightContent}
      </S.Flex>
      <S.Flex overflowAuto column alignStart justifyStart>
        {renderMainContent()}
      </S.Flex>
    </S.Module>
  );
}

import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// Routes
import { routeNames } from '../../routing/routes';

// Styles
import S from '../../styled';

// Model Requests
import { getCampaignProgram } from '../../data/requests/program';
import { signUpForCampaignProgram, checkForApplication } from '../../data/requests/programApplication';

// Components
import Page from '../../components/Page';
import Module from '../../components/Module';
import ModuleHeader from '../../components/ModuleHeader';
import HtmlContent from '../../components/HtmlContent';

// Constants
import { FLASH_TYPE_SUCCESS, FLASH_TYPE_ERROR } from '../../helpers/constants';

// Helpers
import navigateTo from '../../helpers/navigateTo';

export default function CampaignProgramSignUpPage(props) {
  const history = useHistory();

  const [programDetails, setProgramDetails] = useState({});
  const [isLoadingProgram, setIsLoadingProgram] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [signUpButtonText, setSignUpButtonText] = useState('Sign Up');
  const [signUpDisabled, setSignUpDisabled] = useState(false)

  const { programId } = props.match.params;
  const { store, setStore } = props;

  const { token, t } = store;
  const { setRenderFlashMessage } = setStore;

  const ALLOWED_PROGRAM_STATUS_RESPONSES = ['pending', 'active', 'declined'];

  const successMessage = useCallback(() => {
    setRenderFlashMessage(
      t('pages.CampaignProgramSignUp.applicationSuccess'),
      FLASH_TYPE_SUCCESS
    );
  }, [setRenderFlashMessage, t]);

  const errorMessage = useCallback(() => {
    setRenderFlashMessage(
      t('pages.CampaignProgramSignUp.signupError'),
      FLASH_TYPE_ERROR
    );
  }, [setRenderFlashMessage, t]);

  const signUpForProgram = useCallback(async () => {
    try {
      setSignUpButtonText('Submitting...');
      const application = await signUpForCampaignProgram(token, programId);
      if (ALLOWED_PROGRAM_STATUS_RESPONSES.includes(application?.status)) {
        successMessage();
        setSignUpButtonText('Signed Up');
        setSignUpDisabled(true);
      } else {
        errorMessage();
      }
    } catch (error) {
      errorMessage();
    }
  }, [token, programId, history, errorMessage, successMessage]);

  const getProgramDetailsAndCheckApplication = useCallback(async () => {
    if (!token) return;

    setIsLoadingProgram(true);
    try {
      const program = await getCampaignProgram(token, programId);
      setProgramDetails(program);
      const application = await checkForApplication(token, programId);
      if (application) {
        setSignUpButtonText('Signed Up');
        setSignUpDisabled(true);
      }
    } catch (error) {
      setHasError(true);
      console.error('getProgramDetails', error);
    } finally {
      setIsLoadingProgram(false);
    }
  }, [token, programId]);

  useEffect(() => {
    getProgramDetailsAndCheckApplication();
  }, [getProgramDetailsAndCheckApplication]);

  const navigateToSettings = (e) => {
    e.preventDefault();
    navigateTo(history, routeNames.settings, token);
  };

  const moduleTitle = (
    <ModuleHeader moduleName={programDetails?.activation_name} history={history} />
  );

  const campaignSignUpButton = (
    <S.Flex column alignCenter flex={1} overflowHidden marginTop={15} >
      <S.Flex justifyCenter alignCenter desktopStyles={{ width: '50%' }}>
        <S.Button
          onClick={signUpForProgram}
          secondary={signUpButtonText !== 'Sign Up'}
          disabled={signUpDisabled}
        >
          {signUpButtonText}
        </S.Button>
      </S.Flex>
    </S.Flex>
  )

  return (
    <Page>
      <Module
        flex={1}
        titleBlock={moduleTitle}
        isLoading={isLoadingProgram}
        hasError={hasError}
      >
        <S.Flex
          flex={1}
          padding={20}
          column
        >
          <HtmlContent content={programDetails?.description || programDetails?.influencer_incentive} flex={1} textAlignLeft />
          {campaignSignUpButton}
          <S.Flex column alignCenter flex={1} overflowHidden marginTop={15} desktopStyles={{ width: '80%' }} >
            <S.Text textAlignCenter smallSize lightWeight paddingTop={20} >
                {t('pages.CampaignProgramSignUp.verifyNotificationsEnabled')}
                <S.Link
                  textDecoration="underline"
                  lightWeight
                  onClick={navigateToSettings}
                >
                  {t('navigation.settings')}.
                </S.Link>
            </S.Text>
          </S.Flex>
        </S.Flex>
      </Module>
    </Page>
  );
};

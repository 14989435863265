import styled from 'styled-components';
import {
  clickable,
  padding,
  margin,
  width,
  height,
  truncate,
  whiteSpace,
  textTransform,
  wordBreak,
  textDecoration,
  lineHeight,
  textUnderline,
} from '../Mixins';

const Text = styled.p`
  font-size: ${({ theme, fontSize }) => {
    if (theme.text.fontSize.larger) return theme.text.fontSize.larger
    if (fontSize) return fontSize;
    return theme.fontSize;
  }};

  font-weight: ${({ theme, lightWeight, mediumWeight, boldWeight }) => {
    if (lightWeight) return theme.text.fontWeight.light;
    if (mediumWeight) return theme.text.fontWeight.medium;
    if (boldWeight) return theme.text.fontWeight.bold;
    return theme.fontWeight;
  }};

  color: ${({ theme, muted, warning, input, highlight }) => {
    if (muted) return theme.text.color.muted;
    if (highlight) return theme.text.color.highlight;
    if (warning) return theme.text.color.warning;
    if (input) return theme.input.color.text;
    return theme.text.color.default;
  }};

  white-space: ${({ nowrap, whiteSpaceInitial }) => {
    if (whiteSpaceInitial) return 'initial';
    if (nowrap) return 'nowrap';
  }};

  overflow-wrap: ${({ overflowWrap, overflowWrapAnywhere }) => {
    return overflowWrapAnywhere ? 'anywhere' : overflowWrap;
  }};

  flex: ${({ flex }) => {
    return flex !== undefined ? flex : null;
  }};

  font-family: ${({ theme, paragraph, labelCaption }) => {
    if (labelCaption) return `'IBM Plex Sans', sans-serif !important`;
    if (theme.text.fontFamily) return `${theme.text.fontFamily}, sans-serif !important`;
    if (paragraph) return `'Inter', sans-serif !important`;
    return 'inherit';
  }};

  text-align: ${({ textAlignCenter, textAlign }) => {
    if (textAlignCenter) return 'center';
    if (textAlign) return textAlign;
  }};

  transition: ${({ hoverable }) => (hoverable ? 'all 0.3s ease-in-out' : null)};

  ${clickable}
  ${padding}
  ${margin}
  ${width}
  ${height}
  ${truncate}
  ${whiteSpace}
  ${wordBreak}
  ${textTransform}
  ${textDecoration}
  ${lineHeight}
  ${textUnderline}

  &:hover {
    opacity: ${({ hoverable }) => (hoverable ? '0.5' : null)};
  }
`;

export default Text;

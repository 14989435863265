import React from 'react';
import S from '../../styled';

// Feature Flag
import { useFeatureFlag } from '../../helpers/useFeatureFlag';

// Components
import Page from '../../components/Page';
import ProfileModule from '../../components/ProfileModule';
import TasksModule from '../../components/TasksModule';
import PerformanceModule from '../../components/PerformanceModule';
import PartnershipModule from '../../components/PartnershipModule';
import AvailableCampaignProgramsModule from '../../components/CampaignProgramsModule/AvailableCampaignProgramsModule';
import NewsFeedModule from '../../components/NewsFeedModule';
import MyCampaignsModule from '../../components/Campaigns/MyCampaigns';

export default function Dashboard(props) {
  const { store, setStore } = props;
  const { t } = store;
  const availableCampaignTitle = t('pages.Campaigns.availableCampaigns');
  const socialProfiles = store?.currentProfile?.social_profiles;

  const portalAffiliateDisabled = useFeatureFlag(store, 'portal_affiliate_disabled');
  const portalNewsFeedEnabled = useFeatureFlag(store, 'portal_news_feed');
  const portalCampaignSignUpFlag = useFeatureFlag(store, 'portal_campaign_sign_up');
  const portalCampaignSummary = useFeatureFlag(store, 'portal_campaign_summary');

  return (
    <Page title={t('navigation.dashboard')} alignStart justifyStart>
      <S.Visibility mobile maxWidth>
        <S.Nav>
          <ProfileModule {...props} socialProfiles={socialProfiles} />
        </S.Nav>
      </S.Visibility>
      <TasksModule store={store} setStore={setStore} />
      {portalCampaignSummary && <MyCampaignsModule store={store} setStore={setStore} completed={false} dashboardView={true} />}
      {portalCampaignSignUpFlag && <AvailableCampaignProgramsModule store={store} dashboardView={true} title={availableCampaignTitle} />}
      {portalNewsFeedEnabled &&
        <NewsFeedModule store={store} setStore={setStore} />
      }
      {!portalAffiliateDisabled &&
        <>
          <PartnershipModule store={store} setStore={setStore} fullDisplay={false} />
          <PerformanceModule store={store} />
        </> 
      }
    </Page>
  );
}

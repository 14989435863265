import styled, { css } from 'styled-components';
import { padding } from '../Mixins';

export const base = css`
	font-family: inherit;
	font-size: inherit;
	color: ${({ theme }) => theme.input.color.text};
	font-weight: ${({ theme }) => theme.input.fontWeight};
	width: 100%;
	background: ${({ theme }) => theme.input.color.background};
	border-radius: ${({ theme }) => theme.input.borderRadius};
`;

const Textarea = styled.textarea`
    ${base}
    ${padding}
    width: 100%;
`;

export default Textarea;

import React, { useEffect, useState, useCallback } from 'react';
import S from '../../styled';

// Routes
import { routeNames } from '../../routing/routes';

// Icons
import {
  Bars as BarsIcon
} from '@styled-icons/fa-solid';

// Helpers
import navigateTo from '../../helpers/navigateTo';

// Model Requests
import { getAccountsByUser } from '../../data/requests/account';

export default function TopNav(props) {
  const { history, setStore } = props;
  const { renderNavButton } = props.routeSettings;
  const { account, renderNavigation, token } = props.store;
  const [accounts, setAccounts] = useState([]);
  const { setRenderNavigation, setToken } = setStore;

  const [isLoading, setIsLoading] = useState(false);

  const getAccounts = useCallback(async () => {
    if (!token) return;

    try {
      setIsLoading(true)
      const data = await getAccountsByUser(token);
      setAccounts(data)
    } catch (error) {
      console.error('getAccountsByUser: ', error);
    } finally {
      setIsLoading(false);
    }
  }, [token]);

  useEffect(() => {
    if (account && !isLoading) {
      setIsLoading(true);
    }
  }, [account, isLoading]);

  const navigateToDashboard = () => {
    navigateTo(history, routeNames.dashboard, token);
  };

  const onAccountChange = (e) => {
    e.preventDefault();
    const token = e.target.value

    setToken(token);
    navigateTo(history, routeNames.dashboard, token);
    window.location.reload();
  };

  const logoUrl = account.portal_logo_url || account.logo_url;
  const accountName = account.portal_brand_name || account.name;

  const toggleNavigation = () => setRenderNavigation(!renderNavigation);

  const logo = (
    <S.Flex row flex={1}>
      <S.Link onClick={navigateToDashboard}>
        <S.NavLogo
          layout={false}
          src={logoUrl}
          alt={`${accountName} logo`}
          initial={isLoading && { scale: 0 }}
          animate={isLoading && { scale: 1 }}
          transition={isLoading && { duration: 1 }}
        />
      </S.Link>
    </S.Flex>
  );

  const accountLogoOrName = () => {
    if (accounts) {
      return (
        <S.Flex column flex={0} flexWrap row paddingTop={20}>
          <S.Visibility mobile>
            {logoUrl && logo}
          </S.Visibility>
          <S.Flex row flex={1}>
            <S.Select onClick={getAccounts} onChange={onAccountChange}>
              <S.Option key={account.id} value={account.portal_token}>
                  {accountName}
              </S.Option>
              {accounts.map((account) => (
                <S.Option key={account.id} value={account.portal_token}>
                  {account.portal_brand_name || account.name}
                </S.Option>
              ))}
            </S.Select>
          </S.Flex>
        </S.Flex>
      );
    } else if (logoUrl) {
      return logo
    } else if (accountName) {
      return <S.Text as="h1">{accountName}</S.Text>;
    } else {
      return null;
    }
  }

  return (
    <S.TopNav>
      <S.Visibility mobile justifyCenter>
        <S.Flex column justifyCenter flex={0}>
          {accountLogoOrName()}
          {renderNavButton && (
              <S.NavButton onClick={toggleNavigation}>
                <BarsIcon />
              </S.NavButton>
          )}
        </S.Flex>
      </S.Visibility>
      <S.Visibility tablet desktop justifyCenter alignCenter>
        <S.Flex justifyCenter alignCenter flex={1}>
          {accountLogoOrName()}
        </S.Flex>
      </S.Visibility>
    </S.TopNav>
  );
}

import styled from 'styled-components';
import { breakpoint, breakpointNames } from '../Mixins';

const Select = styled.select`
  position: relative;
  font-family: inherit;
  font-size: inherit;
  cursor: pointer;
  line-height: inherit;
  max-width: 343px;
  height: 50px;
  padding: 10px;
  border-width: 1px;
  border-style: solid;
  color: ${({ theme }) => theme.text.color.default};
  font-weight: ${({ theme }) => theme.input.fontWeight};
  border-radius: ${({ theme }) => theme.input.borderRadius};
  border-color: ${({ theme }) => theme.input.color.background};
  background-color: ${({ theme }) => theme.body.color.shading};

  &:focus {
    outline-color: ${({ theme }) => theme.input.color.outline};
  }

  ${breakpoint(breakpointNames.tablet, `
    width: 343px;
  `)};
`;

export default Select;

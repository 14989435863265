import React, { useState, useEffect, useCallback } from 'react';
import S from '../../styled';

// Components
import Module from '../../components/Module';
import Conversion from '../../components/Conversion';
import { ReactComponent as PerformanceImage } from '../../assets/svg/module_enhancement.svg';

// Requests
import { getConversionsByToken } from '../../data/requests/conversion';

export default function ConversionsModule(props) {
  const { token, t } = props.store;
  const { flex } = props;

  const [isMounted, setIsMounted] = useState(true);
  const [conversions, setConversions] = useState([]);
  const [moduleIsLoading, setModuleIsLoading] = useState(true);
  const [isEmpty, setIsEmpty] = useState(false);

  const loadConversions = useCallback(async () => {
    if (!token) return;

    let data;
    try {
      setModuleIsLoading(true);
      data = await getConversionsByToken(token);
      setConversions(data);
    } catch (error) {
      console.error('loadConversions: ', error);
    } finally {
      setIsEmpty(!data?.length);
      if (isMounted) setModuleIsLoading(false);
    }
  }, [token, isMounted]);

  useEffect(() => {
    loadConversions();
    return () => setIsMounted(false);
  }, [loadConversions]);

  const topRightContent = (
    <S.Text muted smallSize nowrap marginLeft={14} marginRight={14}>
      {t('components.ConversionsModule.thisMonth')}
    </S.Text>
  );

  const emptyContent = (
    <>
      <S.Visibility tablet desktop width='100%'>
        <S.Flex flex={1} column alignCenter justifyCenter textAlignCenter overflowHidden>
          <S.Text muted paddingBottom={10} textAlignCenter>
            {t('components.ConversionsModule.noConversionData')}
          </S.Text>
          <S.Text muted lightWeight textAlignCenter>
            {t('components.ConversionsModule.showPerformanceAfterUsage')}
          </S.Text>
          <S.SvgWrapper paddingTop={50} height={150} width={150} color='#FFFFFF'>
            <PerformanceImage />
          </S.SvgWrapper>
        </S.Flex>
      </S.Visibility>
      <S.Visibility mobile>
        <S.Flex flex={1} column alignCenter justifyCenter textAlignCenter overflowHidden>
          <S.Text muted lightWeight>
            {t('components.ConversionsModule.conversionsAppearHere')}
          </S.Text>
        </S.Flex>
      </S.Visibility>
    </>
  );

  const renderConversions = () => {
    return (
      <S.Flex justifyStart>
        <S.Visibility desktop>
          <S.Flex flex={1} />
        </S.Visibility>
        <S.Flex flex={2} column lineHeight={2} justifyStart>
          <S.Flex flex={1} row>
            <S.Flex flex={2} justifyStart><S.Text smallSize>{t('components.ConversionsModule.date')}</S.Text></S.Flex>
            <S.Flex flex={1} justifyEnd><S.Text smallSize>{t('components.ConversionsModule.payout')}</S.Text></S.Flex>
            <S.Flex flex={1} justifyEnd><S.Text smallSize>{t('components.ConversionsModule.revenue')}</S.Text></S.Flex>
          </S.Flex>
          {conversions.map((conversion, i) => <Conversion key={`${conversion.id}-${i}`} {...conversion} />)}
        </S.Flex>
        <S.Visibility desktop>
          <S.Flex flex={1} />
        </S.Visibility>
      </S.Flex>
    );
  }

  return (
    <Module
      title={t('components.ConversionsModule.yourConversions')}
      isLoading={moduleIsLoading}
      isEmpty={isEmpty}
      flex={flex}
      emptyContent={emptyContent}
      topRightContent={topRightContent}
    >
      <S.Flex column gap={4} justifyStart>
        {renderConversions()}
      </S.Flex>
    </Module>
  );
}

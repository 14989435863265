import styled from 'styled-components';
import { flex, breakpointNames } from '../Mixins';
import { breakpoint } from 'styled-components-breakpoint';

const TopNav = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  position: fixed;
  width: 100%;
  min-height: 80px;
  max-height: 80px;
  height: auto;
  overflow-x: clip;
  overflow-y: clip;
  top: 0;
  left: 0;
  z-index: 50;
  background-color: ${({ theme }) => theme.body.color.shading};
  padding-left: 24px;
  padding-right: 24px;

  ${breakpoint(breakpointNames.tablet)`
    left: unset;
    position: relative;
    justify-content: center;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 24px;
    background-color: ${({ theme }) => theme.module.color.background};
  `}

  ${flex}
`;

export default TopNav;

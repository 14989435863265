import moment from 'moment';

// Helpers
import navigateTo from '../helpers/navigateTo';
import {
  AUTHED_STATUS_AUTHENTICATED,
  AUTHED_STATUS_UNAUTHENTICATED,
  AUTHED_STATUS_INVALID
} from '../helpers/constants';

// Routes
import { routeNames, paths } from '../routing/routes';

const _attemptingToPickDelegateRelationship = (currentUser) => {
  return currentUser?.acting_as_delegate && !currentUser?.delegate_relationship_id;
}
const _saveRequestedPath = (portal_token, pathname) => {
  if (!pathname) {
    return console.error('pathname is missing from _saveRequestedPath');
  }

  const thirtyMinutesFromNow = moment().add(30, 'minutes');
  const data = { pathname, expiresAt: thirtyMinutesFromNow };

  const requestedPathKey = `requested_path_${portal_token}`;
  localStorage.setItem(requestedPathKey, JSON.stringify(data));
};

const _isRequestedPathExpired = (expiresAt) => {
  // NOTE: expiresAt needs to be parsed due to how it's stored in localStorage.
  // Both times are in epoch/unix time
  const currentTimeAsEpoc = Date.parse(moment());
  return Date.parse(expiresAt) < currentTimeAsEpoc;
};

export const shouldNavigateToNotFoundPage = ({ history, portal_token }) => {
  if (!portal_token) {
    navigateTo(history, routeNames.notFound, portal_token);
  }
};

export const shouldNavigateToInvalidPage = ({ history, portal_token, currentUser, authedStatus }) => {
  const creatorAccountLocked = currentUser?.account_locked;
  const creatorAuthenticationIsInvalid = authedStatus === AUTHED_STATUS_INVALID;
  if (creatorAccountLocked || creatorAuthenticationIsInvalid) {
    navigateTo(history, routeNames.invalid, portal_token);
  }
};

export const shouldNavigateToProfileReviewPage = ({ history, portal_token, currentUser, authedStatus }) => {
  // Intentionally checking current user here; we don't want to navigate here
  // until we know that currentUser has been loaded.
  const creatorProfilesNotConfirmed = currentUser && !currentUser.profiles_confirmed;
  const creatorIsAuthenticated = authedStatus === AUTHED_STATUS_AUTHENTICATED;
  const notAttempingToPickDelegateRelationship = !_attemptingToPickDelegateRelationship(currentUser)
  if (creatorProfilesNotConfirmed && creatorIsAuthenticated && notAttempingToPickDelegateRelationship) {
    navigateTo(history, routeNames.profileReview, portal_token);
  }
};

export const shouldNavigateToLastPrivateRouteVisitedOrDashboardPage = ({ history, portal_token, currentUser, authedStatus, lastPrivateRouteVisited }) => {
  const creatorIsAuthenticated = authedStatus === AUTHED_STATUS_AUTHENTICATED;
  const dashboardFullPath = paths[routeNames.dashboard];
  const notAttempingToPickDelegateRelationship = !_attemptingToPickDelegateRelationship(currentUser);
  if (currentUser && creatorIsAuthenticated && notAttempingToPickDelegateRelationship) {
    navigateTo(history, lastPrivateRouteVisited || dashboardFullPath, portal_token, { pathKeyisFullPath: true });
  }
}

export const shouldNavigateToLoginPage = ({ history, portal_token, authedStatus, pathname, userIsLoggingOut, isPrivate }) => {
  if (authedStatus !== AUTHED_STATUS_UNAUTHENTICATED) return;

  if (isPrivate && !userIsLoggingOut) {
    _saveRequestedPath(portal_token, pathname);
  };

  navigateTo(history, routeNames.login, portal_token);
};

export const shouldNavigateToRequestedPage = ({ history, portal_token, currentUser, authedStatus }) => {
  if (authedStatus !== AUTHED_STATUS_AUTHENTICATED) return;
  if (_attemptingToPickDelegateRelationship(currentUser)) return;

  const requestedPathKey = `requested_path_${portal_token}`;
  const requestedPathData = JSON.parse(localStorage.getItem(requestedPathKey));

  if (!requestedPathData) return;

  if (_isRequestedPathExpired(requestedPathData.expiresAt)) {
    localStorage.removeItem(requestedPathKey);
    return;
  }

  localStorage.removeItem(requestedPathKey);

  const relativePath = requestedPathData.pathname;
  navigateTo(history, relativePath, portal_token, { pathKeyisFullPath: true });
};

export const shouldNavigateToDelegateRelationshipSelectionPage = ({ history, portal_token, currentUser, authedStatus }) => {
  const creatorIsAuthenticated = authedStatus === AUTHED_STATUS_AUTHENTICATED;
  const attempingToPickDelegateRelationship = _attemptingToPickDelegateRelationship(currentUser);

  if (attempingToPickDelegateRelationship && creatorIsAuthenticated) {
    navigateTo(history, routeNames.delegates, portal_token);
  }
};

import S from '../../styled';

export default function DelegateLoginNote(props) {
  const { currentUser } = props;

  if (!currentUser || !currentUser.login_email || currentUser.login_delegation_role === "self") {
    return null;
  }

  // TODO: translations!
  const T = {delegate: "Delegate", agent: "Agent"};

  return (
    <S.Text muted lightWeight textAlignCenter>
      {`Logged in as: ${currentUser.login_email} (${T[currentUser.login_delegation_role]})`}
    </S.Text>
  );
}

import React, { useState } from 'react';
import S from '../../styled';

const TikTokModal = props => {
  const [username, setUsername] = useState(undefined);
  const [canSubmit, setCanSubmit] = useState(!!props.profile);
  const { t } = props.store;

  const updateUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
    setCanSubmit(username.length);
  }

  const confirm = () => {
    if (props.profile === undefined) { // add
      props.confirm(username, true);
    } else {
      const { username, id } = props.profile;
      props.confirm({ username, id }, false);
    }
  }

  return (
    <S.Flex alignCenter textAlign='center' column>
      {props.profile && (
        <S.Flex alignCenter textAlign='center' column>
          <S.Flex>
            {t('components.TikTokModal.confirmTikTokAccount')}
          </S.Flex>
          <S.Flex column margin={20}>
            <S.Flex>
              {props.profile.name}
            </S.Flex>
            <S.Flex>
              {props.profile.username}
            </S.Flex>
          </S.Flex>
        </S.Flex>
      )}
      {!props.profile && (
        <S.Flex alignCenter textAlign='center' column>
          <S.Flex>
            {t('components.TikTokModal.enterTikTokUsername')}
          </S.Flex>
          <S.Flex marginTop={20}>
            <S.Input value={username} onChange={updateUsername} />
          </S.Flex>
        </S.Flex>
      )}
      <S.Flex gap={20} margin={20}>
        <S.Button gap={8} onClick={confirm} disabled={!canSubmit}>
          {t('navigation.confirm')}
        </S.Button>
        <S.Button secondary onClick={props.cancel}>
          {t('navigation.cancel')}
        </S.Button>
      </S.Flex>
    </S.Flex>
  );
}

export default TikTokModal;

import React from 'react';

// Components
import Page from '../../components/Page';
import PerformanceModule from '../../components/PerformanceModule';
import ConversionsModule from '../../components/ConversionsModule';

export default function Performance(props) {
  const { store, setStore } = props;
  const { t } = store;

  return (
    <Page title={t('navigation.performance')} flexStart justifyStart>
      <PerformanceModule store={store} />
      <ConversionsModule store={store} setStore={setStore} />
    </Page>
  );
}
